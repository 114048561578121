import css from './ProjectPreview.module.scss';
import classnames from 'classnames';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Box, Icon, Tag } from '@core';
import { padForNum, Performance } from '@utils';
import { ViewportContext } from '@context';
import useIntersectionObserver from '@hooks/useIntersectionObserver';

export interface ProjectPreviewProps {
	num?: number;
	title?: string;
	text?: string;
	url?: string;
	picture?: Picture;
	className?: string;
}

const resistance = 0.075;

export const ProjectPreview: FC<ProjectPreviewProps> = React.memo(
	({ num, title, text, url, picture, className }) => {
		const [element, setElement] = useState<HTMLAnchorElement | null>(null);

		const { bp } = useContext(ViewportContext);
		const isMob = bp === 'xs';

		// убрал в рамках https://redcollar.atlassian.net/browse/SUPP-53

		// useEffect(() => {
		// 	if (!element || isMob) return;

		// 	let center = {
		// 		x: 0,
		// 		y: 0,
		// 	};
		// 	const current = {
		// 		x: 0,
		// 		y: 0,
		// 	};
		// 	const next = {
		// 		x: 0,
		// 		y: 0,
		// 	};

		// 	const pic = element.querySelector(`.${css.pictureWrap}`);

		// 	const onMove = (e: MouseEvent) => {
		// 		if (!pic) return;

		// 		const { left: parentLeft, top: parentTop } = element.getBoundingClientRect();
		// 		const { left, top } = pic.getBoundingClientRect();

		// 		center.x = left - parentLeft + 40;
		// 		center.y = top - parentTop + 40;

		// 		next.x = center.x - (e.clientX - parentLeft);
		// 		next.y = center.y - (e.clientY - parentTop);

		// 		next.x = next.x * 0.15;
		// 		next.y = next.y * 0.35;
		// 	};

		// 	const onLeave = () => {
		// 		next.x = 0;
		// 		next.y = 0;
		// 	};

		// 	const onTick = () => {
		// 		const diffX = next.x - current.x;
		// 		const deltaX = Math.abs(diffX) < 0.01 ? 0 : diffX * resistance;

		// 		const diffY = next.y - current.y;
		// 		const deltaY = Math.abs(diffX) < 0.01 ? 0 : diffY * resistance;

		// 		if (current.x === next.x && current.y === next.y) return;

		// 		if (deltaX) {
		// 			current.x = current.x + deltaX;
		// 		} else {
		// 			current.x = next.x;
		// 		}

		// 		if (deltaY) {
		// 			current.y = current.y + deltaY;
		// 		} else {
		// 			current.y = next.y;
		// 		}

		// 		element.style.setProperty('--mx', `${current.x}`);
		// 		element.style.setProperty('--my', `${current.y}`);
		// 	};

		// 	element.addEventListener('mousemove', onMove);
		// 	element.addEventListener('mouseleave', onLeave);

		// 	Performance.addListener(onTick);

		// 	return () => {
		// 		element.removeEventListener('mousemove', onMove);
		// 		element.removeEventListener('mouseleave', onLeave);

		// 		Performance.removeListener(onTick);
		// 	};
		// }, [element, isMob]);

		const contentRef = useRef<HTMLDivElement | null>(null);

		// убрал в рамках https://redcollar.atlassian.net/browse/SUPP-53
		
		// const entry = useIntersectionObserver(contentRef, {
		// 	threshold: 0.25,
		// 	freezeOnceVisible: true,
		// });

		const entity = (
			<a
				ref={setElement}
				className={classnames(className, css.module, css.visible)}> {/* поменял { [css.visible]: !!entry?.isIntersecting } на visible в рамках https://redcollar.atlassian.net/browse/SUPP-53 */}
				<Box className={css.left}>{num && padForNum(num, '00')}</Box>
				<Box ref={contentRef} className={css.middle}>
					<Icon className={css.arrow} id="link" />
					<Tag className={css.title} type="h3" isHTML={true} content={title} />
					<Box className={css.text}>{text}</Box>
				</Box>
				<Box className={css.right}>
					<Box className={css.pictureWrap}>
						{picture && (
							<picture className={css.picture}>
								<Image
									src={picture.src}
									alt={picture.alt}
									layout="fill"
									sizes="(max-width: 639px) 100vw, 27vw"
								/>
							</picture>
						)}
					</Box>
					<Box className={css.tab}>{num && padForNum(num, '00')}</Box>
				</Box>
			</a>
		);

		return url ? <Link href={url}>{entity}</Link> : entity;
	}
);
