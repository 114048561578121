import css from './HomeCareerRegular.module.scss';
import type { HomeCareerProps } from '@components/HomeCareer/HomeCareer';
import React, {
	FC,
	HTMLAttributes,
	MutableRefObject,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Box, Container, RawHtml, Section, SquareArrowLink, Tag } from '@core';
import { HomeCareerMedia } from '@components/HomeCareer/components/HomeCareerMedia/HomeCareerMedia';
import { ViewportContext } from '@context';
import { useScrollWithEase } from '@hooks';
import classnames from 'classnames';

const stripsRange = [0.1, 0.25, 0.4, 0.55, 0.7];

export const HomeCareerRegular: FC<HomeCareerProps> = ({ cover, video, ...rest }) => {
	const ref = useRef<HTMLDivElement | null>(null);
	const scrollWay = 3.6;

	return (
		<Section
			ref={ref}
			className={css.module}
			style={{
				height: `${scrollWay * 100}vh`,
				minHeight: `100vh`,
			}}>
			<Box className={css.sticky}>
				<HomeCareerMedia cover={cover} video={video} />
			</Box>
			<HomeCareerContent {...rest} />
			{/* убрал в рамках https://redcollar.atlassian.net/browse/SUPP-53 */}
			{/* <HomeCareerStrips ref={ref} /> */}
		</Section>
	);
};

export const HomeCareerContent: FC<HomeCareerProps & HTMLAttributes<HTMLDivElement>> = React.memo(
	({ title, text, url, className }) => {
		return (
			<Box className={classnames(className, css.track)}>
				<Box className={css.content}>
					<Container>
						<Box className={css.contentBox}>
							<Tag className={css.title} type="h1" content={title} isHTML={true} />
							<RawHtml className={css.text} content={text} />
							{url && <SquareArrowLink url={url} className={css.link} />}
						</Box>
					</Container>
				</Box>
			</Box>
		);
	}
);

export const HomeCareerStrips = React.memo(
	React.forwardRef<HTMLDivElement, {}>(({}, ref) => {
		const { vh } = useContext(ViewportContext);
		const [seek, setSeek] = useState(0);

		const onScroll = useCallback(
			(current: number) => {
				const seek = 1 - Math.min(current, vh) / vh;
				const relative = Math.max(Math.min(seek, 1.5), 0);

				setSeek(relative);
			},
			[vh]
		);

		useScrollWithEase(ref as MutableRefObject<HTMLDivElement | null>, onScroll, {
			ease: 0.25,
			edge: 'top',
		});

		useEffect(() => {
			const node = (ref as MutableRefObject<HTMLDivElement | null>).current;
			const strips = node?.querySelectorAll<HTMLDivElement>(`.${css.strip}`);

			strips?.forEach((el, i) => {
				el.style.transform = `translate3d(0, ${seek * (1 + stripsRange[i]) * -vh}px, 0)`;
			});
		}, [ref, seek, vh]);

		return (
			<div className={css.strips}>
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
				<div className={css.strip} />
			</div>
		);
	})
);
