import css from './HomeIntro.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
	useMemo,
} from 'react';
import { Box, Section } from '@core';
import { Logo } from '@components/HomeIntro/components/Logo/Logo';
import { Video } from '@components/HomeIntro/components/Video/Video';
import { FeaturesType, Splash } from '@components/HomeIntro/components/Splash/Splash';
import { SliderInterface, Slider } from '@components/HomeIntro/components/Slider/Slider';
import { useScrollWithEase } from '@hooks';
import { PageLoaderContext, ViewportContext } from '@context';

interface Props {
	features: FeaturesType;
	slider: SliderInterface;
}

export interface HomeChildScreens {
	full: number;
	before?: number;
	after?: number;
}

export interface HomeChildProps {
	screens: HomeChildScreens;
	start: number;
	end: number;
}

interface ConfComponent {
	name: string;
	screens: HomeChildScreens;
	weight: number;
	component: FC<HomeChildProps>;
}

interface Config {
	components: Array<ConfComponent>;
	count: number;
	overlap: number;
}

const getConfig = (sliderSize: number = 1, isMob: boolean): Config => {
	// const splash = isMob ? 1 : 5;
	const splash = 1;  // поменял splash в рамках https://redcollar.atlassian.net/browse/SUPP-53

	let count = splash;

	const components: Array<ConfComponent> = [
		// убрал экран splash в рамках https://redcollar.atlassian.net/browse/SUPP-53
		// {
		// 	name: 'splash',
		// 	screens: {
		// 		full: splash,
		// 	},
		// 	weight: 1,
		// 	component: Splash as FC<HomeChildProps>,
		// },
	];

	if (!isMob) {
		const before = 2;
		const after = 1;
		const screens = sliderSize + before + after;
		count += screens;
		components.push({
			name: 'slider',
			screens: {
				full: screens,
				before,
				after,
			},
			weight: 1,
			component: Slider as FC<HomeChildProps>,
		});
	} else {
		const before = 1;
		const after = 1;
		const screens = sliderSize + before + after;
		count += screens;
		components.push({
			name: 'video',
			screens: {
				full: screens,
				before,
				after,
			},
			weight: 1,
			component: Video as FC<HomeChildProps>,
		});
	}

	return {
		components: components.map((comp) => ({ ...comp, weight: comp.screens.full / count })),
		count,
		overlap: 0.35,
	};
};

export const HomeIntroContext = createContext<{
	seek: number;
	config?: Config;
}>({ seek: 0, config: undefined });

export const HomeIntro: FC<Props> = React.memo((props) => {
	const { vh, bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const config = useMemo(
		() => getConfig(props?.slider?.items?.length, isMob),
		[props?.slider, isMob]
	);
	const { overlap } = config;

	const ref = useRef<HTMLDivElement | null>(null);
	const { ready, setLoadingState } = useContext(PageLoaderContext);

	const [seek, setSeek] = useState(0);

	const onScroll = useCallback(
		(current: number, height: number) => {
			const start = height - vh; //  * (1 - overlap * 2);
			const end = current - vh;
			const absolute = 1 - end / start;
			const relative = Math.max(Math.min(absolute, 1 / (1 - overlap)), 0);

			setSeek(relative);
		},
		[vh, overlap]
	);

	useScrollWithEase(ref, onScroll, {
		ease: 0.5,
		edge: 'bottom',
	});

	// Change loading state on page ready
	useEffect(() => {
		if (ready && setLoadingState) {
			setLoadingState('playing');
		}
	}, [ready, setLoadingState]);

	return (
		<HomeIntroContext.Provider value={{ seek, config }}>
			<HomeIntroLayout
				ref={ref}
				screensCount={config.count}
				components={config.components}
				{...props}
			/>
		</HomeIntroContext.Provider>
	);
});

export const HomeIntroLayout = React.memo(
	React.forwardRef<HTMLDivElement, { screensCount?: number; components: ConfComponent[] }>(
		({ screensCount = 1, ...rest }, ref) => {
			const { bp } = useContext(ViewportContext);
			const isMob = bp === 'xs';

			return (
				<Section
					ref={ref}
					style={{
						height: `${screensCount * (isMob ? 75 : 65)}vh`,
					}}
					className={classnames(css.module, 'first-screen')}>
					<Box className={css.sticky}>
						{/* поменял endPoint в рамках https://redcollar.atlassian.net/browse/SUPP-53 */}
						<Logo endPoint={1 / screensCount} />
						{/* <Logo endPoint={(rest.components[0].screens.full + 1) / screensCount} /> */}
						<HomeIntroContent {...rest} />
					</Box>
				</Section>
			);
		}
	)
);

export const HomeIntroContent: FC<{ components: ConfComponent[] }> = React.memo(
	({ components, ...rest }) => {
		let prev = 0;
		return (
			<>
				{components.map(({ name, screens, weight, component: Child }) => {
					const start = prev;
					prev = weight;
					return (
						<Child
							key={'home-' + name}
							screens={screens}
							start={start}
							end={start + weight}
							{...rest}
						/>
					);
				})}
			</>
		);
	}
);
