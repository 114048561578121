import React, { FC, useContext, useMemo } from 'react';
import { PageProps } from '@api/hooks/types';
import { sections, wrappers } from './components';
import { nanoid } from 'nanoid';
import { ViewportContext } from '@src/context';

interface PageFlowProps extends PageProps {
	options?: {
		isDetailPage?: boolean;
		isSimplePage?: boolean;
	};
}

export const PageFlow: FC<PageFlowProps> = ({ data = [], options = {} }) => {
	const uid = useMemo(nanoid, [data]);
	const { bp } = useContext(ViewportContext);
	const isMob = bp === 'xs';

	const items = data!.map((item, i) => {
		if (!item.type) {
			console.warn(`Type is missing for "${JSON.stringify(item)}"`);
			return null;
		}

		if (!(item.type in sections)) {
			console.warn(`Section with type "${item.type}" is not exist`);
			return null;
		}

		// поменял в рамках https://redcollar.atlassian.net/browse/SUPP-53
		const Wrapper = isMob && item.wrapper?.type ? wrappers[item.wrapper.type] : DefaultWrapper;
		// const Wrapper = item.wrapper?.type ? wrappers[item.wrapper.type] : DefaultWrapper;
		const Section = sections[item.type];

		const providedWrapperProps = item?.wrapper
			? {
					wrapperProps: item?.wrapper?.props,
			  }
			: {};

		return (
			<Wrapper key={`content-${uid}-item-${item.type}-${i}`} {...item.wrapper?.props}>
				<Section links={item.links} {...item.content} {...options} {...providedWrapperProps} />
			</Wrapper>
		);
	});

	return (
		<>
			{items.shift()}
			<div className="page__content">{items}</div>
		</>
	);
};

export const DefaultWrapper: FC = ({ children = [] }) => {
	return <>{children}</>;
};
